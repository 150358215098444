.body {
  margin: 0;
  padding: 0;
}


.img {
  object-fit: cover;
  display: block;
  min-width: 120px;
  max-width: 120px;
  height: 120px;
  border: 3px solid #F3F3F3;
  border-radius: 40px;

}

.section {
  width: 100%;
  z-index: 0;
  overflow: hidden;
  margin-top: 80px;
}

.article {
  display: flex;
  width: 60%;
  animation: bannermove 60s linear infinite;
}

.div {
  width: 100%;
}

.ul {
  display: flex;
  gap: 32px;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.li {
  max-width: 120px;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.05));
}


@keyframes bannermove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-75%);
  }
}