.body {
    margin: 0;
    padding: 0;
  }
  
  
  .img {
    object-fit: fill;
    display: block;
    width: 240px;
    height: 210px;
    border: 3px solid #F3F3F3;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.05));
    border-radius: 20px;
  
  }
  
  .section {
    width: 100%;
    z-index: 0;
    overflow: hidden;
    margin-top: 30px;
  }
  
  .article {
    display: flex;
    width: 470%;
    animation: bannermove 20s linear infinite;
  }
  
  .div {
    width: 100%;
  }
  
  .ul {
    display: flex;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    gap: 12px;
  }
  
  .li {
    width: 100%;
  }
  
  
  @keyframes bannermove {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }