/* https://www.w3schools.com/howto/howto_js_rangeslider.asp */
.slider {
  -webkit-appearance: none;
  height: 6px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  cursor: pointer;
}

/* https://www.w3schools.com/howto/howto_js_rangeslider.asp */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #542ae2;
  cursor: pointer;
}
